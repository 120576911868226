import { apiSlice } from "./apiSlice";
import { CognitoAuth, UpserResponse } from "@/types/login";

export const loginSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendAuthCode: builder.mutation<CognitoAuth, string>({
      query: (authCode) => ({
        url: `${process.env.NEXT_PUBLIC_COGNITO_AUTH_URL}/token?grant_type=authorization_code&code=${authCode}&client_id=${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}&redirect_uri=${process.env.NEXT_PUBLIC_REDIRECT_URI}`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    }),
    getRefreshCognitoAuthentication: builder.query<
      CognitoAuth,
      { refreshToken: string }
    >({
      query: ({ refreshToken }) => ({
        url: `${process.env.NEXT_PUBLIC_COGNITO_AUTH_URL}/token?grant_type=refresh_token&client_id=${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}&refresh_token=${refreshToken}`,
        method: "GET",
        body: JSON.stringify(refreshToken),
      }),
    }),
    upsertUser: builder.mutation<UpserResponse, void>({
      query: () => ({
        url: `/sdk/api/v1/fe/user/upsert`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSendAuthCodeMutation,
  useGetRefreshCognitoAuthenticationQuery,
  useUpsertUserMutation,
} = loginSlice;
