import { SubscriptionKeysItem } from "@/types/subscriptionKeys";
import { apiSlice } from "./apiSlice";
import { CognitoAuth, CognitoAuthResponse, User } from "@/types/login";

export const subscriptionKeysSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    subscriptionKeysGenerate: builder.mutation<string, void>({
      query: () => ({
        url: "/sdk/api/v1/fe/subscription/generate",
        method: "POST",
      }),
      invalidatesTags: [{ type: "SubscriptionKeys" }],
    }),
    getSubscriptionKeysList: builder.query<SubscriptionKeysItem[], void>({
      query: () => ({
        url: "/sdk/api/v1/fe/subscription/list",
        method: "GET",
      }),
      providesTags: ["SubscriptionKeys"],
    }),
    subscriptionKeysDelete: builder.mutation<string, string>({
      query: (displayName) => ({
        url: `/sdk/api/v1/fe/subscription/delete/${displayName}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "SubscriptionKeys" }],
    }),
  }),
});

export const {
  useSubscriptionKeysGenerateMutation,
  useGetSubscriptionKeysListQuery,
  useSubscriptionKeysDeleteMutation,
} = subscriptionKeysSlice;
